<template>
  <eden-confirm-dialog
    title="Delete Issue"
    button-type="danger"
    button-text="Delete"
    :show.sync="setShow"
    @confirm="deleteSuggestion"
    :button-status="deleting"
  >
    <p>
      Are you sure you want to delete this suggestion,
      <strong>{{ suggestion.title }}</strong
      >?
    </p>
  </eden-confirm-dialog>
</template>

<script>
import suggestions from "@/requests/feedback/suggestions";

export default {
  name: "SuggestionDelete",
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    suggestion: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  data() {
    return {
      deleting: false,
    };
  },
  computed: {
    setShow: {
      get() {
        return this.show;
      },
      set(value) {
        this.$emit("update:show", value);
      },
    },
  },
  methods: {
    closeEvent() {
      this.setShow = false;
    },
    deleteSuggestion() {
      this.deleting = true;
      suggestions
        .delete(this.suggestion.id)
        .then((response) => {
          if (response.data.status) {
            this.$message.success(response.data.message);
            this.$emit("success");
            this.closeEvent();
          }
        })
        .catch((error) => {
          this.$message.error(error.response.data.message);
        })
        .finally(() => {
          this.deleting = false;
        });
    },
  },
};
</script>

<style lang="scss" scoped></style>
